import React, {useEffect} from "react";
import './VotesPageSortingModal.css';
import sorting_modal_close_button from "../../../img/VotesPageBlockFilterModal_close_button.svg";
import sorting_modal_decrease_btn from '../../../img/VotesPageBlockSortModal_decrease_btn.svg';
import sorting_modal_increase_bnt from '../../../img/VotesPageBlockSortModal_increase_btn.svg';
import useOnClickOutsideModal from "../../../hooks/useOnClickOutsideModal";

const VotesPageSortingModal = (props) => {

    const {
        active,
        setActive,
        clickSortTypeDec,
        clickSortTypeInc,
        sortType,
        btnActiveVotes,
        btnArchiveVotes
    } = props;

    //clickSortTypeDec функция для сортовки на уменьшение
    // clickSortTypeInc функция для сортировки на увеличение
    const listSortType = btnActiveVotes ? sortType.activeVotes : sortType.archiveVotes ?? [];

    useOnClickOutsideModal(active, () => setActive(false))

    return (
            <div className={active ? 'sorting-modal active' : 'sorting-modal'}>
                <div className={active ? 'sorting-modal__content active' : 'sorting-modal__content'} onClick={e => e.stopPropagation()}>
                    <div className={'sorting-modal__content-title'}>
                        <h3>Сортировать по</h3>
                        <img alt={'кнопка-крестик-закрыть окно'} src={sorting_modal_close_button} onClick={() => setActive(false)}/>
                    </div>
                    {listSortType && listSortType.length > 0 &&
                        <>
                            {listSortType?.map((obj, i) => (
                                <div key={i} className='sorting-modal__types-sort'>
                                    <span>{obj.name}</span>
                                    <div className='types-sort__decrease-increase-btn'>
                                        <img onClick={() => clickSortTypeDec(obj.sortPropertyDec, btnActiveVotes, btnArchiveVotes, setActive(false))}
                                             className={obj.activeBtnDec ?
                                                 'sorting-modal__types-sort-btn-img sorting-modal__types-sort-btn-img__active'
                                                 : 'sorting-modal__types-sort-btn-img'}
                                             alt={'Кнопка сортировки'}
                                             src={sorting_modal_decrease_btn}/>
                                        <img onClick={() => clickSortTypeInc(obj.sortPropertyInc, btnActiveVotes, btnArchiveVotes, setActive(false))}
                                             className={obj.activeBtnInc ?
                                                 'sorting-modal__types-sort-btn-img sorting-modal__types-sort-btn-img__active'
                                                 : 'sorting-modal__types-sort-btn-img'}
                                             alt={'Кнопка сортировки'}
                                             src={sorting_modal_increase_bnt}/>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                    {/*<div className={'sorting-modal__mobile-buttons'}>*/}
                    {/*    <button type={"button"} className={'sorting-modal__mobile-buttons-default-filter'}>Сбросить фильтры</button>*/}
                    {/*    <button type={"button"} className={'sorting-modal__mobile-buttons-apply'}>Применить</button>*/}
                    {/*</div>*/}
                </div>
            </div>
    )
}
export default VotesPageSortingModal;