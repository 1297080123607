import { getEventById } from "../Api/api-service";
import { useQuery } from "@tanstack/react-query";

export const useEventById = (id, options) =>
  useQuery({
    queryKey: ["events", { id: id }],
    queryFn: () => getEventById(id),
    refetchOnMount: true,
    staleTime: 0,
    refetchInterval: data => data?.isProcessing ? 3000 : false,
    ...options,
  });
